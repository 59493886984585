import { Atom, Cmp, Mod } from ":mods";
import { createStore } from "solid-js/store";
import { For, createEffect, createMemo, createSignal, splitProps } from "solid-js";
import { AMaterialTaskContent, MaterialTaskDesignView } from "src/modules/courses/models";
import clone from "clone";

type RecievedPayload = AMaterialTaskContent<{
  image: any;
  description: string;
  title$: string;
  description$?: string;
  row1_count: number;
  row2_count: number;
}>;
type RequestPayload = AMaterialTaskContent<{
  image?: boolean;
  title$: string;
  description$?: string;
  description?: string;
  row1_count: number;
  row2_count: number;
}>;

type RequestPayloadSection = Partial<RequestPayload[0]>;
type RequestPayloadSectionContent = Partial<RequestPayloadSection["content"]>;
type RequestPayloadSectionContentItem = Partial<RequestPayloadSectionContent[0]>;

export default function (props: MaterialTaskDesignView<RecievedPayload, RequestPayloadSectionContent>) {
  // setup vars before content
  let images = [];

  // track parent content
  const content = createMemo(() => {
    // on content updated or discarded
    images = [];
    return props.elements.data.content as RecievedPayload;
  });

  function onDataChange(idx: number, inner_idx: number, props: RequestPayloadSectionContentItem) {
    const items = content()[0].content as any as RequestPayloadSectionContent;

    items[idx][inner_idx] = { ...items[idx][inner_idx], ...props };
    //? this is where you process data before passing it to update
    for (const item of items) {
      // const new_item = { ...item, ...props } as RequestPayloadSectionContentItem;
      if (Array.isArray(item)) {
        for (const nested_item of item) {
          if ("image" in nested_item) {
            if (typeof nested_item.image !== "boolean") {
              nested_item.image = false;
            }
          }
        }
      }
    }
    // pass data to update
    console.log("d :: ", items);
    onDataUpdate(items);
  }

  function onDataUpdate(data: RequestPayloadSectionContent) {
    const formData = new FormData();
    images.map((imagefile, i) => formData.append("images", imagefile, `tp22 image ${i + 1}`));
    formData.append("data", JSON.stringify(data));
    // @ts-ignore
    props.events?.onDataUpdate?.(formData, data);
  }

  const [rowOneCount, setRowOneCount] = createSignal<number>(0);
  const [rowTwoCount, setRowTwoCount] = createSignal<number>(0);
  let rowOne = 0;
  let rowTwo = 0;

  // @ts-ignore
  setRowOneCount(props.elements.data.content[0].content[1].row1_count);
  // @ts-ignore
  setRowTwoCount(props.elements.data.content[0].content[1].row2_count);
  rowOne = rowOneCount();
  rowTwo = rowTwoCount();
  // console.log("dataTopic.content[0].content[1].row1_count", rowOneCount());
  // console.log("dataTopic.content[0].content[1].row2_count", rowTwoCount());

  return (
    <form>
      <For each={content()}>
        {(section, index) => {
          return (
            <>
              <h1 class="text-39px font-700 mt-40px mb-20px">{"VisitorJourney"}</h1>
              <section class="flex flex-wrap w-full pr-0.8rem gap-20px">
                <For each={section.content}>
                  {(content, idx) => {
                    return (
                      <div class={`flex flex-col gap-5px w-full h-full`}>
                        {!!content.title$ && (
                          <div class="flex justify-between gap-10px w-full h-167px ">
                            <div class="w-45%">
                              <Atom.Tasks.Title
                                class="text-61px leading-43px  h-154px w-412px p-10px"
                                elements={{
                                  title: content.title$,
                                }}
                                options={{
                                  editable: false,
                                }}
                              />
                            </div>

                            <div class="w-full h-90%">
                              <Atom.Tasks.Description
                                class="w-full h-full "
                                elements={{
                                  description: content.description$,
                                }}
                                options={{
                                  editable: false,
                                }}
                              />
                            </div>
                          </div>
                        )}

                        <div class="flex flex-row justify-between w-full">
                          {Array.isArray(content) &&
                            content.length > 1 &&
                            (rowOne >= 0
                              ? content.map((obj, i) => {
                                  rowOne = rowOne - 1;
                                  return rowOne >= 0 ? (
                                    <div class="w-212px h-212px">
                                      <Atom.Tasks.Image
                                        class="w-212px h-212px"
                                        options={{
                                          editable: true && props.options.editable,
                                        }}
                                        elements={{
                                          images: images,
                                          image: obj.image,
                                        }}
                                        events={{
                                          // idx()-2
                                          onImageChange: (e) => onDataChange(idx(), i, { image: e }),
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div class="w-212px h-212px">
                                      <Atom.Tasks.Image
                                        class="w-212px h-212px"
                                        options={{
                                          editable: false && props.options.editable,
                                        }}
                                        elements={{
                                          images: images,
                                          image: obj.image,
                                        }}
                                      />
                                    </div>
                                  );
                                })
                              : content.map((obj, i) => {
                                  rowTwo = rowTwo - 1;
                                  return rowTwo >= 0 ? (
                                    <div class="w-212px h-212px">
                                      <Atom.Tasks.Image
                                        class="w-212px h-212px"
                                        options={{
                                          editable: true && props.options.editable,
                                        }}
                                        elements={{
                                          images: images,
                                          image: obj.image,
                                        }}
                                        events={{
                                          // idx()-2
                                          onImageChange: (e) => onDataChange(idx(), i, { image: e }),
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div class="w-212px h-212px">
                                      <Atom.Tasks.Image
                                        class="w-212px h-212px"
                                        options={{
                                          editable: false && props.options.editable,
                                        }}
                                        elements={{
                                          images: images,
                                          image: obj.image,
                                        }}
                                      />
                                    </div>
                                  );
                                }))}
                        </div>

                        {Array.isArray(content) &&
                          content.length == 1 &&
                          content.map((obj, i) => {
                            return (
                              <div class="min-w-full h-120">
                                <Atom.Tasks.Image
                                  class="w-212px h-212px"
                                  options={{
                                    editable: true && props.options.editable,
                                  }}
                                  elements={{
                                    images: images,
                                    image: obj.image,
                                  }}
                                  events={{
                                    // idx()-2
                                    onImageChange: (e) => onDataChange(idx(), i, { image: e }),
                                  }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    );
                  }}
                </For>
              </section>
            </>
          );
        }}
      </For>
    </form>
  );
}
